import React, { useContext, useRef, useState } from "react";
import { motion } from "framer-motion";
import { IoArrowBackOutline } from "react-icons/io5";
import questions from "../helper/data.js"; // Assume this imports your questions data
import Button from "./ui/Button.js";
import Que1Logo from "./quelogos/Que1Logo.js";
import Que2Logo from "./quelogos/Que2Logo.js";
import Que3Logo from "./quelogos/Que3Logo.js";
import Que4Logo from "./quelogos/Que4Logo.js";
import Que5Logo from "./quelogos/Que5Logo.js";
import Que6Logo from "./quelogos/Que6Logo.js";
import MainLogo from "./MainLogo.js";
import { useNavigate } from "react-router-dom";
import { API_Path } from "../API/ApiComment.js";
import { ApiPost } from "../API/API_data.js";
// import { SuccessToast } from "../helper/Toast.js";
import html2canvas from "html2canvas";
import Elephant from "../assets/svg/submitSvgs/Elephant.js";
import Balance from "../assets/svg/submitSvgs/Balance.js";
import Captain from "../assets/svg/submitSvgs/Captain.js";
import Tune from "../assets/svg/submitSvgs/Tune.js";
import Heartly from "../assets/svg/submitSvgs/Heartly.js";
import Dog from "../assets/svg/submitSvgs/Dog.js";
import Cat from "../assets/svg/submitSvgs/Cat.js";
import Bird from "../assets/svg/submitSvgs/Bird.js";
import Rock from "../assets/svg/submitSvgs/Rock.js";
import roleContext from "../contexts/roleContext.js";
import Footer from "./Footer.js";
import axios from "axios";

const QuestionForm = () => {
  const logoRef = useRef();
  const navigate = useNavigate();
  const context = useContext(roleContext);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(
    questions.map((q) => ({ que: q.question, answer: "" }))
  );

  const [selectedIds, setSelectedIds] = useState(
    Array(questions.length).fill(null)
  );
  const [selectedValues, setSelectedValues] = useState(
    Array(questions.length).fill(null)
  );
  const [name, setName] = useState("");
  const [caregivingMoment, setCaregivingMoment] = useState("");
  // const [emailInput, setEmailInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleNext = async (flag) => {
    if (window.innerWidth <= 768) {
      // Check if the screen width is for mobile
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
    }

    const selectedAnswer = answers[currentQuestionIndex].answer;

    if (currentQuestionIndex === 0 && !name.trim()) {
      setErrorMessage("Please enter your name.");
      return;
    }

    if (!selectedAnswer && currentQuestion.type !== "input") {
      setErrorMessage("Please select an option");
      return;
    }

    if (selectedAnswer || currentQuestionIndex === 0) {
      setErrorMessage("");
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // console.log("Final Answers:", answers);
      // console.log("Selected IDs:", selectedIds);

      const grayDotCount = selectedIds[5]?.length || 0;
      const blackDotCount = selectedIds[6]?.length || 0;

      context?.setDotCount({
        grayDot: grayDotCount,
        blackDot: blackDotCount,
      });

      setIsSectionVisible(true);

      setTimeout(async () => {
        if (logoRef.current) {
          try {
            const canvas = await html2canvas(logoRef.current);
            const imageURL = canvas.toDataURL("image/png");

            // Step 1: Convert Base64 to Blob
            const base64Image = imageURL.replace(
              /^data:image\/\w+;base64,/,
              ""
            );
            const binary = atob(base64Image);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i));
            }
            const blob = new Blob([new Uint8Array(array)], {
              type: "image/png",
            });

            // Step 2: Upload Blob to server
            let uploadedImageUrl;
            try {
              const formData = new FormData();
              formData.append("image", blob, "image.png");

              const imageUploadResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_DEV_URL}admin/image`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              uploadedImageUrl = imageUploadResponse.data.data;
            } catch (uploadError) {
              setLoading(false);
              console.error("Image upload failed:", uploadError);
              return;
            }
            setIsSectionVisible(false);

            if (flag) {
              context?.setSubmitData(data);
              handleSubmit(
                uploadedImageUrl,
                grayDotCount,
                blackDotCount,
                flag,
                imageURL
              );
            } else {
              // if (emailInput) {
              //   await getEmailData(imageURL, grayDotCount, blackDotCount);
              // } else {
              context?.setSubmitData(data);
              handleSubmit(
                uploadedImageUrl,
                grayDotCount,
                blackDotCount,
                flag,
                imageURL
              );
              // }
            }
          } catch (error) {
            console.error("Error generating the canvas image: ", error);
          }
        }
      }, 100);
    }
  };

  // <div>
  //   <h5> I discovered my unique caregiving style and here is my tile
  //                 that is a part of Noora Health’s Caregiver Community Mosaic
  //                 </h5>
  //                 <a href="https://demo4.outdoinc.com" target="_blank" rel="noreferrer">
  //     #CaregiverCommunityMosaic
  //   </a></div>
  //   <img src="${url}" alt="Captured Image"/>

  // const getEmailData = async (url, grayDotCount, blackDotCount) => {
  //   setLoading(true);
  //   let body = {
  //     email: emailInput,
  //     subject: "Final Image",
  //     html: `<h1>${data?.title}</h1><img src="${url}" alt="Captured Image"/>
  //     `,
  //   };

  //   try {
  //     const res = await ApiPost(API_Path.emailUrl, body, false);
  //     if (res?.success === true) {
  //       setLoading(false);
  //       SuccessToast("Email sent successfully");
  //       context?.setSubmitData(data);
  //       handleSubmit(url, grayDotCount, blackDotCount);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("error", error);
  //   }
  // };

  // const getEmailData = async (base64Url, grayDotCount, blackDotCount) => {
  //   setLoading(true);

  //   // Step 1: Convert Base64 to Blob
  //   const base64Image = base64Url.replace(/^data:image\/\w+;base64,/, "");
  //   const binary = atob(base64Image);
  //   const array = [];
  //   for (let i = 0; i < binary.length; i++) {
  //     array.push(binary.charCodeAt(i));
  //   }
  //   const blob = new Blob([new Uint8Array(array)], { type: "image/png" });

  //   // Step 2: Upload Blob to server
  //   let uploadedImageUrl;
  //   try {
  //     const formData = new FormData();
  //     formData.append("image", blob, "image.png");

  //     const imageUploadResponse = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_DEV_URL}admin/image`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     uploadedImageUrl = imageUploadResponse.data.data;
  //   } catch (uploadError) {
  //     setLoading(false);
  //     console.error("Image upload failed:", uploadError);
  //     return;
  //   }

  //   // Step 3: Send email with the new image URL
  //   let body = {
  //     // email: emailInput,
  //     subject: "Final Image",
  //     html: `<h1>${data?.title}</h1><img src="${uploadedImageUrl}" alt="Captured Image"/>`,
  //   };

  //   try {
  //     const res = await ApiPost(API_Path.emailUrl, body, false);
  //     if (res?.success === true) {
  //       setLoading(false);
  //       SuccessToast("Email sent");
  //       context?.setSubmitData(data);
  //       handleSubmit(uploadedImageUrl, grayDotCount, blackDotCount);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("Error sending email:", error);
  //   }
  // };

  const handleSubmit = async (
    url,
    grayDotCount,
    blackDotCount,
    flag,
    imageURL
  ) => {
    if (!flag) {
      setLoading(true);
    }

    let finalDesc2 = "";
    let finalDesc3 = "";

    // Set finalDesc2 based on the value at index 1
    if (["A", "B", "C"]?.includes(selectedValues[1])) {
      finalDesc2 =
        "You possess a deep understanding of caregiving, having walked this path yourself and intimately know the challenges and rewards that come with this vital role.";
    } else if (selectedValues[1] === "D") {
      finalDesc2 =
        "While you may not have been a caregiver personally, we've all seen or experienced the transformative power of care, connecting us through shared human experiences.";
    }

    // Logic for finalDesc3 based on 5th and 6th index
    const fifthIndexLength = selectedValues[5]?.length || 0;
    const sixthIndexLength = selectedValues[6]?.length || 0;

    if (fifthIndexLength > sixthIndexLength) {
      finalDesc3 =
        "Caregiving can be a deeply rewarding yet demanding experience. Your dedication to cherishing positive moments is commendable, and acknowledging occasional feelings of overwhelm demonstrates self-awareness and strength.";
    } else if (sixthIndexLength > fifthIndexLength) {
      finalDesc3 =
        "A caregiver's journey encompasses highs and lows. Remember, it's perfectly natural to feel overwhelmed at times and uplifted during the gratifying times, and seeking support is always available.";
    } else if (fifthIndexLength === sixthIndexLength) {
      finalDesc3 =
        "The caregiving experience encompasses both satisfying and challenging aspects. Your dedication to cherishing positive moments is commendable, and acknowledging occasional feelings of overwhelm demonstrates self-awareness and strength.";
    }

    let body = {
      name: name,
      questionAnswer: answers,
      imageLink: url,
      // email: emailInput,
      finalTitle: data?.title,
      finalDesc1: data?.description,
      finalDesc2: finalDesc2,
      finalDesc3: finalDesc3,
      grayDot: grayDotCount,
      blackDot: blackDotCount,
      base64Image: imageURL,
    };
    try {
      const res = await ApiPost(API_Path.createDataUrl, body, false);
      if (res.success === true) {
        setLoading(false);
        navigate(`/submit/${res?.data?._id}`, {
          state: {
            answers,
            selectedIds,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      setSubmitDisabled(false);
      console.log("error", error);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setErrorMessage("");
    }
  };

  const handleButtonClick = () => {
    if (currentQuestionIndex === questions.length - 1 && !submitDisabled) {
      setSubmitDisabled(true); // Disable the button after submit
      handleNext(false); // Proceed with submission logic
    } else if (currentQuestionIndex < questions.length - 1) {
      handleNext(false); // Proceed to next question if not the last question
    }
  };
  const handleSkipButtonClick = () => {
    if (currentQuestionIndex === questions.length - 1 && !submitDisabled) {
      setSubmitDisabled(true); // Disable the button after submit
      handleNext(true); // Proceed with submission logic
    } else if (currentQuestionIndex < questions.length - 1) {
      handleNext(true); // Proceed to next question if not the last question
    }
  };

  const handleOtherChange = (otherValue, id) => {
    const updatedAnswers = [...answers];
    const currentAnswer = updatedAnswers[currentQuestionIndex].answer;

    // Check if "other" is selected in the current answers
    const hasOther = currentAnswer.includes("other");

    if (otherValue.trim() === "") {
      // If the textarea is empty, remove "other" from the selected answers
      updatedAnswers[currentQuestionIndex].answer = currentAnswer.filter(
        (ans) => ans !== "other"
      );
      delete updatedAnswers[currentQuestionIndex].otherValue; // Remove the stored "other" value

      // Also remove the associated ID from selectedIds
      setSelectedIds((prevIds) => {
        const newIds = [...prevIds];
        newIds[currentQuestionIndex] = newIds[currentQuestionIndex]?.filter(
          (selectedId) => selectedId !== id
        );
        return newIds;
      });
    } else {
      if (!hasOther) {
        // Add "other" to the answers if not already selected
        updatedAnswers[currentQuestionIndex].answer = [
          ...currentAnswer,
          "other",
        ];
      }

      // Update or set the 'other' value
      updatedAnswers[currentQuestionIndex].otherValue = otherValue;

      // Add the ID to selectedIds if it's not already present
      setSelectedIds((prevIds) => {
        const newIds = [...prevIds];
        if (!newIds[currentQuestionIndex]?.includes(id)) {
          newIds[currentQuestionIndex] = [
            ...(newIds[currentQuestionIndex] || []),
            id,
          ];
        }
        return newIds;
      });
    }

    setAnswers(updatedAnswers);
    setErrorMessage("");
  };

  const handleOptionChange = (value, id) => {
    const updatedAnswers = [...answers];
    const currentQuestion = questions[currentQuestionIndex];

    const selectedOption = currentQuestion.options.find(
      (option) => option.value === value
    );
    const selectedLabel = selectedOption ? selectedOption.label : "";

    if (currentQuestion.type === "checkbox") {
      if (!Array.isArray(updatedAnswers[currentQuestionIndex].answer)) {
        updatedAnswers[currentQuestionIndex].answer = [];
      }

      const currentAnswer = updatedAnswers[currentQuestionIndex].answer;

      if (currentAnswer.includes(selectedLabel)) {
        // Unselect the option
        updatedAnswers[currentQuestionIndex].answer = currentAnswer.filter(
          (ans) => ans !== selectedLabel
        );
        setSelectedIds((prevIds) => {
          const newIds = [...prevIds];
          newIds[currentQuestionIndex] = newIds[currentQuestionIndex]?.filter(
            (selectedId) => selectedId !== id
          );
          return newIds;
        });
        setSelectedValues((prevValues) => {
          const newValues = [...prevValues];
          newValues[currentQuestionIndex] = newValues[
            currentQuestionIndex
          ].filter((selectedValue) => selectedValue !== value);
          return newValues;
        });
      } else {
        // Select the option
        updatedAnswers[currentQuestionIndex].answer = [
          ...currentAnswer,
          selectedLabel,
        ];
        setSelectedIds((prevIds) => {
          const newIds = [...prevIds];
          newIds[currentQuestionIndex] = [
            ...(newIds[currentQuestionIndex] || []),
            id,
          ];
          return newIds;
        });
        setSelectedValues((prevValues) => {
          const newValues = [...prevValues];
          newValues[currentQuestionIndex] = [
            ...(newValues[currentQuestionIndex] || []),
            value,
          ];
          return newValues;
        });
      }

      // Preserve "Other" value if it exists
      const otherIndex = currentAnswer.indexOf("other");
      if (otherIndex > -1) {
        updatedAnswers[currentQuestionIndex].answer.push(
          "other",
          currentAnswer[otherIndex + 1]
        );
      }
    } else if (currentQuestion.type === "radio") {
      updatedAnswers[currentQuestionIndex].answer = [selectedLabel];
      setSelectedIds((prevIds) => {
        const newIds = [...prevIds];
        newIds[currentQuestionIndex] = id;
        return newIds;
      });
      setSelectedValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[currentQuestionIndex] = value;
        return newValues;
      });
    }

    setAnswers(updatedAnswers);
    setErrorMessage("");
  };

  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    setName(nameValue);
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[0].answer = [nameValue];
      return updatedAnswers;
    });
    setErrorMessage("");
  };

  const handleCaregivingMomentChange = (e) => {
    const caregivingMomentValue = e.target.value;
    setCaregivingMoment(caregivingMomentValue);
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[7].answer = [caregivingMomentValue];
      return updatedAnswers;
    });
    setErrorMessage("");
  };

  // const handleEmailChange = (e) => {
  //   const email = e.target.value;
  //   setEmailInput(email);

  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!email) {
  //     setErrorMessage("");
  //   } else if (emailRegex.test(email)) {
  //     setAnswers((prevAnswers) => {
  //       const updatedAnswers = [...prevAnswers];
  //       updatedAnswers[8].answer = [email];
  //       return updatedAnswers;
  //     });
  //     setErrorMessage("");
  //   } else {
  //     currentQuestionIndex === 8 &&
  //       setErrorMessage("Please enter a valid email address.");
  //   }
  // };

  const currentQuestion = questions[currentQuestionIndex];

  function getNameFromValues(selectedValues) {
    let response = {
      title: "Unknown",
      description: "No matching description found.",
      svg: null,
    };

    if (selectedValues[2] === "A" && selectedValues[3] === "A") {
      response.title = "Dependable Rock";
      response.description =
        "Your unyielding resilience serves as a foundation of trust, empowering those in your care to face adversity with confidence, knowing you're always there to support them.";
      response.svg = Rock;
    } else if (selectedValues[2] === "A" && selectedValues[3] === "B") {
      response.title = "Balancing resolute";
      response.description =
        "You are far-sighted and dependable. You do not let your emotions overwhelm you and you make sure to maintain an aura of calm for the person in your care.";
      response.svg = Balance;
    } else if (selectedValues[2] === "A" && selectedValues[3] === "C") {
      response.title = "Catalyst in tune";
      response.description =
        "You are deeply connected to the person you care for. You feel their emotions and perhaps that’s what drives you to provide the care needed for them to heal.";
      response.svg = Tune;
    } else if (selectedValues[2] === "B" && selectedValues[3] === "A") {
      response.title = "Pragmatic Possum";
      response.description =
        "You are resourceful and adaptable. Your air of calm and sense of duty make anyone you care for feel at ease knowing things are taken care of.";
      response.svg = Cat;
    } else if (selectedValues[2] === "B" && selectedValues[3] === "B") {
      response.title = "Hearty helper";
      response.description =
        "You are there to help out with the day-to-day, while being an essential support for the person you’re caring for. You are able to hold down the fort while experts or others handle the hard decisions.";
      response.svg = Heartly;
    } else if (selectedValues[2] === "B" && selectedValues[3] === "C") {
      response.title = "The care-ier";
      response.description =
        " You fiercely support the person you care for and make sure they feel at ease. You may cry with them, feel relief with them, and carry some of their pain. You truly are an empathetic support.";
      response.svg = Bird;
    } else if (selectedValues[2] === "C" && selectedValues[3] === "A") {
      response.title = "Steadfast ally";
      response.description =
        "You are constantly boosting the morale of the one you care for, distracting them from their pain and worries —all the while staying composed and providing a shoulder to lean on.";
      response.svg = Elephant;
    } else if (selectedValues[2] === "C" && selectedValues[3] === "B") {
      response.title = "Captain compassion";
      response.description =
        "You are all heart. By motivating them, cheering them on, and paving the path to recovery, you are a positive force of support to those you care for. You don't let your own emotions get in the way.";
      response.svg = Captain;
    } else if (selectedValues[2] === "C" && selectedValues[3] === "C") {
      response.title = "Motivation retriever";
      response.description =
        "You are a joy and respite for someone who is unwell. You are in tune with their experiences and have their back as they go through a rollercoaster of emotions.";
      response.svg = Dog;
    } else {
      response.title = "No matching name found";
    }

    return response;
  }

  let data = getNameFromValues(selectedValues);

  return (
    <>
      <div
        className={`flex h-[calc(100vh-73px)] md:h-dvh md:overflow-auto text-[#383838]`}
      >
        <div
          className={`bg-[#fdfaf7] w-full flex ${
            currentQuestionIndex === 7 && "z-10"
          } ${
            currentQuestionIndex === 5 || currentQuestionIndex === 6
              ? "md:justify-start justify-center"
              : "justify-center"
          } gap-20 lg:gap-1 ${
            currentQuestionIndex === 5 || currentQuestionIndex === 6
              ? "md:flex-col"
              : ""
          } relative ${
            currentQuestionIndex === 3 ||
            currentQuestionIndex === 4 ||
            currentQuestionIndex === 6
              ? "flex-row-reverse"
              : ""
          }`}
          style={
            currentQuestionIndex === 0 ||
            currentQuestionIndex === 7 ||
            currentQuestionIndex === 8
              ? {
                  backgroundImage:
                    "url(https://sapient-dev-bucket.s3.ap-south-1.amazonaws.com/dental-web-app/uploads/26137192963309872087.Microsite_Banner01-1-1.png)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100vw",
                  // height: "100vh",
                }
              : {}
          }
        >
          {/* <div
          className={`text-[90px] p-6 absolute left-3 ${
            currentQuestionIndex === 4 ? "bottom-0" : ""
          }`}
        >
          Q{currentQuestionIndex + 1}
        </div> */}

          <motion.div
            key={currentQuestionIndex}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            className={`flex ${
              currentQuestionIndex === 3 || currentQuestionIndex === 4
                ? "justify-end items-end"
                : ""
            }`}
          >
            <div
              className={`h-[calc(100vh-73px)] md:h-dvh md:overflow-auto flex flex-col justify-center px-28 w-[750px] 1xl:w-[450px] md:w-full ${
                currentQuestionIndex === 5 ||
                currentQuestionIndex === 6 ||
                currentQuestionIndex === 7
                  ? "md:h-auto"
                  : ""
              } ${
                currentQuestionIndex === 7 || currentQuestionIndex === 0
                  ? "lg:w-full"
                  : ""
              }  1xl:px-10 sm:px-5 ${
                currentQuestionIndex === 2 || currentQuestionIndex === 4
                  ? "lg:justify-end pb-10 sm:pb-5"
                  : currentQuestionIndex === 8 ||
                    currentQuestionIndex === 0 ||
                    currentQuestionIndex === 7
                  ? "lg:justify-center"
                  : "lg:justify-start"
              }  lg:pt-10 sm:pt-2 ${
                currentQuestionIndex === 5 ||
                currentQuestionIndex === 6 ||
                currentQuestionIndex === 7
                  ? "lg:w-[80%]"
                  : "lg:w-[65%]"
              }`}
            >
              {currentQuestionIndex !== 0 && (
                <button
                  onClick={handleBack}
                  className="text-[#898884] py-2 transition flex items-center gap-2 sm:text-[13px]"
                  disabled={currentQuestionIndex === 0}
                >
                  <IoArrowBackOutline /> Back
                </button>
              )}
              <h2 className="text-lg sm:text-[15px] font-semibold mb-4 max-w-[700px] sm:leading-5">
                {currentQuestionIndex === 0
                  ? "What can we call you?"
                  : currentQuestionIndex === 1
                  ? `Hi ${name}! ${questions[currentQuestionIndex]?.question}`
                  : questions[currentQuestionIndex]?.question}{" "}
              </h2>

              {currentQuestion.type === "input" ? (
                <div className="sm:text-[13px]">
                  {currentQuestionIndex === 0 && (
                    <textarea
                      type="text"
                      value={name}
                      onChange={handleNameChange}
                      placeholder={currentQuestion.placeholder}
                      className="rounded-lg p-2 w-fit mb-4 py-3 outline-none border border-[#383838] min-w-[350px] xs:min-w-[280px]"
                    />
                  )}
                  {currentQuestionIndex === 7 && (
                    <textarea
                      value={caregivingMoment}
                      onChange={handleCaregivingMomentChange}
                      placeholder={currentQuestion.placeholder}
                      className="rounded-lg p-2 w-fit mb-4 py-3 outline-none border border-[#383838] min-w-[350px] xs:min-w-full"
                    />
                  )}
                  {/* {currentQuestionIndex === 8 && (
                  <div>
                    <input
                      type="email"
                      value={emailInput}
                      onChange={handleEmailChange}
                      placeholder={currentQuestion.placeholder}
                      className="rounded-lg p-2 w-fit mb-4 py-3 outline-none border border-[#383838] min-w-[350px] xs:min-w-full"
                    />
                  </div>
                )} */}
                </div>
              ) : (
                <div className="flex flex-col mb-4 sm:mb-0 w-fit sm:text-[13px] sm:leading-4">
                  {currentQuestion?.options?.map((option, index) => (
                    <label
                      key={option.value}
                      className="mb-3 flex justify-between gap-4 items-center w-fit"
                    >
                      <input
                        type={
                          currentQuestion.type === "checkbox"
                            ? "checkbox"
                            : "radio"
                        }
                        value={option?.value}
                        checked={
                          currentQuestion.type === "checkbox"
                            ? Array.isArray(
                                answers[currentQuestionIndex].answer
                              ) &&
                              answers[currentQuestionIndex].answer.includes(
                                option.label
                              )
                            : answers[currentQuestionIndex].answer[0] ===
                              option.label
                        }
                        // onChange={() =>
                        //   handleOptionChange(option.value, option.id)
                        // }
                        onChange={() => {
                          if (option.label !== "other") {
                            handleOptionChange(option.value, option.id);
                          }
                        }}
                        className="hidden"
                      />
                      <div
                        className={`cursor-pointer p-2 border-2 rounded-lg ${
                          (currentQuestion.type === "checkbox" &&
                            answers[currentQuestionIndex].answer.includes(
                              option.label
                            )) ||
                          (currentQuestion.type === "radio" &&
                            answers[currentQuestionIndex].answer[0] ===
                              option.label) ||
                          (option.label === "other" &&
                            answers[currentQuestionIndex].answer.includes(
                              "other"
                            ))
                            ? "border-[#383838]"
                            : "border-gray-400 bg-white"
                        }`}
                      >
                        <span>
                          {option.label === "other" ? "" : option.label}{" "}
                          {option.label === "other" && (
                            <textarea
                              className={`outline-none min-w-[290px] resize-none ${
                                answers[currentQuestionIndex].otherValue
                                  ? "bg-[#fdfaf7]"
                                  : "bg-white"
                              }`}
                              placeholder="Other _____________"
                              value={
                                answers[currentQuestionIndex].answer.includes(
                                  "other"
                                )
                                  ? answers[currentQuestionIndex].otherValue ||
                                    ""
                                  : ""
                              }
                              onChange={(e) =>
                                handleOtherChange(e.target.value, option.id)
                              }
                              onInput={(e) => {
                                e.target.style.height = "20px";
                                e.target.style.height = `${e.target.scrollHeight}px`;
                              }}
                              rows={1}
                            />
                          )}
                        </span>
                      </div>
                    </label>
                  ))}
                </div>
              )}

              {errorMessage && (
                <p className="text-red-600 mb-2 text-sm">{errorMessage}</p>
              )}
              <div className="flex gap-3 justify-between items-end">
                <div onClick={handleButtonClick}>
                  <Button
                    title={
                      currentQuestionIndex < questions.length - 1
                        ? "Next"
                        : loading
                        ? "Submitting..."
                        : "Submit"
                    }
                    stop={
                      submitDisabled &&
                      currentQuestionIndex === questions.length - 1
                    }
                  />
                </div>
                {currentQuestionIndex === 7 && (
                  <div
                    className={`mt-5 underline ${
                      submitDisabled &&
                      currentQuestionIndex === questions.length - 1
                        ? ""
                        : "cursor-pointer"
                    } `}
                    onClick={handleSkipButtonClick}
                    // onClick={() => handleNext(true)}
                  >
                    Skip
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </div>

        <div
          className={`${
            isSectionVisible ? "h-1" : "h-[calc(100vh-73px)] md:h-dvh"
          } flex ${
            currentQuestionIndex === 2
              ? "justify-start items-start"
              : currentQuestionIndex === 7
              ? "justify-center items-center"
              : "justify-end items-end"
          } `}
        >
          {[
            <Que1Logo id={selectedIds} />,
            <Que2Logo id={selectedIds} />,
            <Que3Logo id={selectedIds} />,
            <Que4Logo id={selectedIds} />,
            <Que5Logo id={selectedIds} />,
            <Que6Logo id={selectedIds} />,
            <div>
              {isSectionVisible && (
                <div className="my-10 1xl:pr-10 md:pr-0 absolute right-0 z-0">
                  <div ref={logoRef}>
                    <MainLogo id={selectedIds} data={data} />
                  </div>
                  {/* <h1 className="text-center mt-10 text-2xl font-playfair font-semibold">
                    {data?.title}
                  </h1>
                  <div className="max-w-96 text-center mt-5">
                    <p className="font-medium text-sm">{data?.description}</p>
                  </div> */}
                </div>
              )}
            </div>,
            // <div ref={logoRef}>
            //   <div className="p-5 sm:p-2 flex relative sm:flex-col">
            //     <div className="bg-white">
            //       <MainLogo id={selectedIds} data={data} />
            //     </div>
            //     <div className="w-5 ps-8 sm:pt-2 sm:ps-0 sm:text-center sm:w-full">
            //       <h1 className="text-center text-2xl md:text-[16px] font-playfair font-semibold text-[#383838] transform rotate-90 origin-left text-nowrap sm:rotate-0">
            //         {data?.title}
            //       </h1>
            //     </div>
            //   </div>
            //   <div className="max-w-[380px] text-[#383838] text-xs pb-6 sm:px-2 px-5">
            //     <p className="">{data?.description}</p>
            //     <p className="pt-2 md:pt-1">
            //       {["A", "B", "C"]?.includes(selectedValues[1])
            //         ? "You possess a deep understanding of caregiving, having walked this path yourself and intimately know the challenges and rewards that come with this vital role."
            //         : selectedValues[1] === "D"
            //         ? "While you may not have been a caregiver personally, we've all seen or experienced the transformative power of care, connecting us through shared human experiences."
            //         : ""}
            //     </p>
            //     <p className="pt-2 md:pt-1">
            //       {selectedValues[5]?.length > selectedValues[6]?.length
            //         ? "Caregiving can be a deeply rewarding yet demanding experience. Your dedication to cherishing positive moments is commendable, and acknowledging occasional feelings of overwhelm demonstrates self-awareness and strength."
            //         : selectedValues[6]?.length > selectedValues[5]?.length
            //         ? "A caregiver's journey encompasses highs and lows. Remember, it's perfectly natural to feel overwhelmed at times and uplifted during the gratifying times, and seeking support is always available."
            //         : selectedValues[5]?.length === selectedValues[6]?.length
            //         ? "The caregiving experience encompasses both satisfying and challenging aspects. Your dedication to cherishing positive moments is commendable, and acknowledging occasional feelings of overwhelm demonstrates self-awareness and strength."
            //         : ""}
            //     </p>
            //   </div>
            // </div>,
          ][currentQuestionIndex - 1] || null}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuestionForm;
