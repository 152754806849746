import React, { useEffect, useState } from "react";
import FinalImageSec from "./FinalImageSec";
import CommunityCount from "./CommunityCount";
import { ApiGet } from "../API/API_data";
import { API_Path } from "../API/ApiComment";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND_DEV_URL);

// const socket = io(process.env.REACT_APP_BACKEND_DEV_URL, {
//   transports: ["websocket", "polling"], // Use only WebSocket transport
// });

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(null);

  useEffect(() => {
    getTileData();

    socket.on("dataUpdate", (updatedData) => {
      // setData((prevData) => [...prevData, updatedData]);
      if (updatedData?.data && updatedData?.count) {
        setData(updatedData?.data);
        setCount(updatedData?.count);
      }
    });

    return () => {
      socket.off("dataUpdate");
    };
  }, []);

  const getTileData = async () => {
    try {
      const res = await ApiGet(API_Path.imageTileUrl);
      if (res?.success === true) {
        setData(res?.data);
        setCount(res?.count);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="flex lg:flex-col-reverse">
      <div className="w-full bg-[#fdfaf7] h-screen lg:h-auto overflow-auto">
        <FinalImageSec data={data} />
      </div>
      <div className="min-w-[450px] md:min-w-min px-6 pb-6 h-screen bg-[#fdfaf7] lg:h-auto py-5 overflow-auto flex justify-center">
        <div className="xxl:h-[-webkit-fill-available] ">
          <CommunityCount count={count} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
