import React from "react";

const M21 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M28.9362 80.2847C34.0426 107.423 49.3617 118.731 80 119.296L80 147C34.6099 146.435 8.51065 123.819 1.70214 80.2847C0.567384 72.9347 9.19351e-06 52.5808 9.19351e-06 52.5808L8.89695e-06 50.8847L0 6.10352e-05L27.234 5.62734e-05L27.234 52.5808C27.234 52.5808 27.2341 72.9347 28.9362 80.2847Z"
          fill="white"
        />
        <path
          d="M28.9362 80.2847C34.0426 107.423 49.3617 118.731 80 119.296L80 147C34.6099 146.435 8.51065 123.819 1.70214 80.2847C0.567384 72.9347 9.19351e-06 52.5808 9.19351e-06 52.5808L8.89695e-06 50.8847L0 6.10352e-05L27.234 5.62734e-05L27.234 52.5808C27.234 52.5808 27.2341 72.9347 28.9362 80.2847Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="146"
        viewBox="0 0 79 146"
        fill="none"
      >
        <path
          d="M28.8149 79.8068C33.8506 106.743 49.1812 117.999 78.9473 118.391V146C34.1863 145.496 8.33676 123.152 1.79045 79.6948C0.44762 72.2466 0 52.1423 0 52.1423V50.2942V0.00531006H26.8566V52.1423C26.8566 52.1423 27.1364 72.6386 28.8149 79.7508V79.8068Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M21;
