import React from "react";

const M23 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          d="M0 80.0001V6.10352e-05H27.234V52.9578H80V80.0001H0Z"
          fill="white"
        />
        <path
          d="M0 80.0001V6.10352e-05H27.234V52.9578H80V80.0001H0Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
      >
        <path
          d="M28.591 78.8084H26.7482C26.7482 78.8084 17.2549 78.8084 4.91361 78.8084C2.23315 78.8084 -0.000564575 76.5727 -0.000564575 73.8899C-0.000564575 62.0966 -0.000564575 52.0359 -0.000564575 52.0359V50.2473V50.1915V0H26.804V52.0359H78.8496V78.8643H28.7027L28.591 78.8084Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M23;
