import React from "react";

const M11 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M28.9362 66.7154C34.0426 39.5769 49.3617 28.2692 80 27.7039L80 -1.27115e-06C34.6099 0.565375 8.51065 23.1808 1.70214 66.7154C0.567384 74.0654 9.19351e-06 94.4192 9.19351e-06 94.4192L8.89695e-06 96.1154L0 147L27.234 147L27.234 94.4192C27.234 94.4192 27.2341 74.0654 28.9362 66.7154Z"
          fill="white"
        />
        <path
          d="M28.9362 66.7154C34.0426 39.5769 49.3617 28.2692 80 27.7039L80 -1.27115e-06C34.6099 0.565375 8.51065 23.1808 1.70214 66.7154C0.567384 74.0654 9.19351e-06 94.4192 9.19351e-06 94.4192L8.89695e-06 96.1154L0 147L27.234 147L27.234 94.4192C27.234 94.4192 27.2341 74.0654 28.9362 66.7154Z"
          fill={!flag ? color : SelectedColor}
          // fill={color}
          // fill-opacity={!flag ? "0.16" : ""}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="146"
        viewBox="0 0 79 146"
        fill="none"
      >
        <path
          d="M28.8149 66.1932C33.8506 39.2567 49.1812 28.0005 78.9473 27.6085V0C34.1863 0.504009 8.33676 22.8484 1.79045 66.3052C0.44762 73.7534 0 93.8577 0 93.8577V95.7058V145.995H26.8566V93.8577C26.8566 93.8577 27.1364 73.3614 28.8149 66.2492V66.1932Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M11;
