import React from "react";
import Q21 from "../../assets/svg/LogoSvg/Q21";
import Q22 from "../../assets/svg/LogoSvg/Q22";
import Q23 from "../../assets/svg/LogoSvg/Q23";

const Que2Logo = ({ id }) => {
  return (
    <div className="relative">
      <div
        className={`absolute right-0 top-0 ${id[2] === "Q2o1" ? "z-10" : ""}`}
      >
        <Q21 color="#F5F5F5" SelectedColor="#00B9EA" flag={id[2] === "Q2o1"} />
      </div>
      <div
        className={`absolute right-0 top-0 ${id[2] === "Q2o2" ? "z-10" : ""}`}
      >
        <Q22 color="#F5F5F5" SelectedColor="#00B9EA" flag={id[2] === "Q2o2"} />
      </div>
      <div className="absolute right-0 top-0">
        <Q23 color="#F5F5F5" SelectedColor="#00B9EA" flag={id[2] === "Q2o3"} />
      </div>
    </div>
  );
};

export default Que2Logo;
