import React from "react";

const Q31 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[277px] h-[277px] lg:w-[127px] lg:h-[127px]"
        viewBox="0 0 277 277"
        fill="none"
      >
        <path
          d="M277 3.45946V276.757H182.785V91.6757L0.243164 93.5517V0L277 3.45946Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="279"
        height="279"
        className="w-[279px] h-[279px] 2xl:w-[220px] 2xl:h-[220px] xl:w-[176px] xl:h-[176px] lg:w-[126px] lg:h-[127px]"
        viewBox="0 0 279 279"
        fill="none"
      >
        <path
          d="M177.85 0.348022H184.37C184.37 0.348022 217.955 0.348022 261.615 0.348022C271.098 0.348022 279 8.25305 279 17.7391C279 59.4381 279 95.0107 279 95.0107V101.335V101.532V279H184.172V95.0107H0.046875V0.15036H177.455L177.85 0.348022Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q31;
