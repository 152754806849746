import React from "react";

const M41 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path d="M80 80V3.05176e-05H52.766V52.9578H0V80H80Z" fill="white" />
        <path
          d="M80 80V3.05176e-05H52.766V52.9578H0V80H80Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
      >
        <path
          d="M50.2586 78.8084H52.1014C52.1014 78.8084 61.5947 78.8084 73.936 78.8084C76.6165 78.8084 78.8502 76.5727 78.8502 73.8899C78.8502 62.0966 78.8502 52.0359 78.8502 52.0359V50.2473V50.1915V0H52.0456V52.0359H0V78.8643H50.1469L50.2586 78.8084Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M41;
