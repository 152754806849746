import React from "react";

const M44 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="146"
        viewBox="0 0 146 146"
        fill="none"
      >
        <path
          d="M76.9515 79.9351C77.0632 79.3202 77.1749 78.5936 77.2866 77.7553C78.0684 77.6435 78.7943 77.5317 79.4644 77.4199C122.799 70.8246 145.024 45.0022 145.582 0.288239H118.052C117.661 30.0231 106.381 45.3376 79.5761 50.3679C79.3527 50.3679 79.0735 50.4797 78.7943 50.5356C71.1438 52.1006 52.1014 52.3242 51.9897 52.3242C51.9897 52.3801 51.7105 71.4394 50.2027 79.1526C50.2027 79.432 50.0911 79.7115 50.0352 79.9351C45.0094 106.819 29.7084 118.054 0 118.445V146C44.6743 145.497 70.4737 123.196 77.0073 79.8233L76.9515 79.9351Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M44;
