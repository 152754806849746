export const API_Path = {
  emailUrl: "admin/send-email",
  createDataUrl: "admin/create-data",
  submitUrl: (id) => `admin/data/${id}`,

  // dashboard api
  tileDataUrl: "/admin/data",
  titleDataUrl: "/admin/title-data",
  imageTileUrl: "/admin/dashboard/data",
};
