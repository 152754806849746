import React from "react";

const Q14 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[239px] h-[278px] lg:w-[125px] lg:h-[122px]"
        viewBox="0 0 239 278"
        fill="none"
      >
        <path
          d="M238.6 0.8C238.7 0.5 238.9 0.3 239 0V95.5C236.7 95.8 234.7 96.2 232.8 96.6H230.8C136.9 114.2 98 174.3 96 278H0C2 121.5 78.2 26.2 230.8 2.7C232.8 0.8 236.7 0.8 238.6 0.8Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[516px] h-[280px] 2xl:w-[411px] 2xl:h-[223px] xl:w-[327px] xl:h-[177px] lg:w-[234px] lg:h-[127px]"
        viewBox="0 0 516 280"
        fill="none"
      >
        <path
          d="M233.952 101.933C138.748 119.747 98.9643 173.979 97.5788 279.277L-1.22076e-05 279.277C1.78135 120.934 80.7549 29.4914 234.348 6.33376C260.672 1.58347 331.728 1.45003e-05 331.728 1.45003e-05L338.26 1.47858e-05L516 2.25551e-05L516 95.0058L331.728 95.0058C331.728 95.0058 259.287 95.9954 234.15 101.933L233.952 101.933Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q14;
