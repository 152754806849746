import React from "react";

const PopTileCard = ({ data }) => {
  
  return (
    <div className="flex flex-col justify-center items-center w-fit">
      <div className="flex relative gap-4">
        <div className="bg-white p-3">
          <img
            src={data?.imageLink}
            alt="tileimage"
            className="w-[200px] xxs:w-[150px]"
          />
        </div>
        <div className="w-5 relative">
          <h1 className="text-center absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[20px] md:text-[16px] font-playfair font-semibold text-[#383838] transform rotate-90 origin-center text-nowrap">
            {data?.finalTitle}
          </h1>
        </div>
      </div>
      <div className="max-w-[380px] text-[#383838] text-xs pt-3 pb-5 sm:px-2 px-5">
        <p
          className={`pt-2 ${
            data?.finalTitle === "Captain compassion" ||
            data?.finalTitle === "Dependable Rock" ||
            data?.finalTitle === "The care-ier"
              ? "font-poppins"
              : "font-playfair"
          }`}
        >
          {data?.finalDesc1}
        </p>
        {/* <p className="pt-3 md:pt-2">{data?.finalDesc2}</p>
        <p className="pt-3 md:pt-2">{data?.finalDesc3}</p> */}
      </div>
    </div>
  );
};

export default PopTileCard;
