import React from "react";

const M13 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path d="M0 0V80H27.234V27.0423H80V0H0Z" fill="white" />
        <path
          d="M0 0V80H27.234V27.0423H80V0H0Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
      >
        <path
          d="M28.591 0.191574H26.7482C26.7482 0.191574 17.2549 0.191574 4.91361 0.191574C2.23315 0.191574 -0.000564575 2.42728 -0.000564575 5.11012C-0.000564575 16.9034 -0.000564575 26.9641 -0.000564575 26.9641V28.7527V28.8085V79H26.804V26.9641H78.8496V0.135674H28.7027L28.591 0.191574Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M13;
