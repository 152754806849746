import React from "react";

const Que5Logo = ({ id }) => {
  const getFillColor = (index) =>
    id[5]?.includes(`Lg${index}`) ? "#AFAFAF" : "#F5F5F5";

  const icons = [1, 2, 3, 4, 5];

  return (
    <div>
      {/* Left side dots */}
      <div className="absolute top-1/2 left-20 transform -translate-y-1/2 flex justify-between flex-col gap-20 1xl:gap-10 w-[200px] min-h-[350px]  1xl:bottom-[30px] 1xl:min-h-min 1xl:top-auto 1xl:w-[167px] md:w-[100px] md:left-10 md:bottom-1">
        {icons.slice(0, 2).map((icon) => (
          <div
            key={`icon-${icon}`}
            className={`flex ${
              icon === 1 ? "justify-start items-start" : "justify-end items-end"
            } `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[60px] h-[60px] 1xl:w-[40px] 1xl:h-[40px]"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                fill={getFillColor(icon)}
              />
            </svg>
          </div>
        ))}
      </div>

      {/* Right side dots */}
      <div className="absolute top-1/3 right-20 bottom-48 flex justify-between flex-col gap-20 1xl:gap-10 w-[200px] lg:top-auto md:right-10 lg:h-[325px] md:h-[225px] lg:bottom-24 md:bottom-10">
        {icons.slice(2, 5).map((icon) => (
          <div
            key={`icon-${icon}`}
            className={`flex ${
              icon === 3
                ? "justify-start items-start lg:items-center lg:justify-center md:items-end md:justify-end"
                : "justify-end items-end"
            } `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[60px] h-[60px] 1xl:w-[40px] 1xl:h-[40px]"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                fill={getFillColor(icon)}
              />
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Que5Logo;
