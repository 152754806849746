import React from "react";

const Q11 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[277px] h-[516px] lg:w-[127px] lg:h-[234px]"
        viewBox="0 0 277 516"
        fill="none"
      >
        <path
          d="M100.192 234.185C117.872 138.923 170.915 99.2308 277 97.2462L277 -1.2603e-05C119.837 1.98458 29.4681 81.3692 5.89365 234.185C1.96457 259.985 3.22711e-05 331.431 3.22711e-05 331.431L3.12301e-05 337.385L0 516L94.2978 516L94.2978 331.431C94.2978 331.431 94.2979 259.985 100.192 234.185Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[280px] h-[516px] 2xl:w-[223px] 2xl:h-[411px] xl:w-[177px] xl:h-[327px] lg:w-[127px] lg:h-[234px]"
        viewBox="0 0 280 516"
        fill="none"
      >
        <path
          d="M101.933 233.952C119.747 138.748 173.979 98.9643 279.277 97.5788V0C120.934 1.78136 29.4914 80.7549 6.33375 234.348C1.58346 260.672 0 331.728 0 331.728V338.26V516H95.0058V331.728C95.0058 331.728 95.9954 259.287 101.933 234.15V233.952Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q11;
