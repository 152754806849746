import React from "react";

const Q12 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[512px] h-[509px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 512 509"
        fill="none"
      >
        <path
          d="M228.863 241.038C230.819 241.038 232.774 241.038 236.686 239.082C236.686 237.127 236.686 233.215 238.642 231.259C262.111 78.6965 355.537 2.41541 512 0.459495L512 96.2999C408.343 98.2558 350.122 137.374 332.52 231.259L332.52 233.215C326.652 260.598 326.652 327.099 326.652 327.099C326.652 327.099 260.156 327.099 232.775 332.967L230.819 332.967C136.941 350.57 97.8252 403.38 95.8694 509L0.035765 509C-1.92002 354.482 76.3116 264.509 228.863 241.038Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[516px] h-[516px] 2xl:w-[410px] 2xl:h-[410px] xl:w-[327px] xl:h-[327px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 516 516"
        fill="none"
      >
        <path
          d="M243.764 233.593C243.368 235.767 242.973 238.336 242.578 241.301C239.812 241.696 237.244 242.091 234.873 242.487C81.5676 265.806 2.93909 357.109 0.963501 515.21H98.3601C99.743 410.073 139.65 355.924 234.478 338.137C235.269 338.137 236.256 337.742 237.244 337.544C264.31 332.011 331.677 331.22 332.073 331.22C332.073 331.023 333.06 263.632 338.394 236.36C338.394 235.372 338.79 234.384 338.987 233.593C356.767 138.536 410.899 98.8128 516 97.4294V0C357.953 1.77863 266.681 80.6312 243.566 233.989L243.764 233.593Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q12;
