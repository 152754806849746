import React from "react";

const M14 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="78"
        viewBox="0 0 69 78"
        fill="none"
      >
        <path
          d="M0.184839 78H28.1848C25.3848 38.4 53.6848 27.5 68.1848 27V0C9.38484 4.8 -1.64849 54 0.184839 78Z"
          fill="white"
        />
        <path
          d="M0.184839 78H28.1848C25.3848 38.4 53.6848 27.5 68.1848 27V0C9.38484 4.8 -1.64849 54 0.184839 78Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="79"
        viewBox="0 0 146 79"
        fill="none"
      >
        <path
          d="M66.1932 28.8149C39.2567 33.8506 28.0005 49.1812 27.6085 78.9473L3.4509e-06 78.9473C0.504011 34.1863 22.8484 8.33675 66.3052 1.79045C73.7534 0.447616 93.8577 -4.10265e-06 93.8577 -4.10265e-06L95.7058 -4.18343e-06L145.995 -6.38163e-06L145.995 26.8566L93.8577 26.8566C93.8577 26.8566 73.3614 27.1364 66.2492 28.8149L66.1932 28.8149Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M14;
