import React from 'react'

const BlackDot = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          d="M8.04906 14.7239C6.67109 14.7527 5.31552 14.3723 4.15374 13.6308C2.99196 12.8892 2.07613 11.8199 1.52205 10.5579C0.967973 9.29588 0.800524 7.89793 1.04087 6.54078C1.28122 5.18363 1.91858 3.92822 2.87236 2.93326C3.82614 1.93831 5.05352 1.2485 6.39931 0.951047C7.7451 0.653592 9.14887 0.761852 10.4331 1.26214C11.7174 1.76242 12.8245 2.63226 13.6144 3.76169C14.4044 4.89112 14.8417 6.22941 14.8711 7.60737C14.8907 8.52265 14.7297 9.43281 14.3974 10.2859C14.0651 11.1389 13.568 11.9181 12.9345 12.579C12.3009 13.2399 11.5434 13.7695 10.7052 14.1375C9.8669 14.5055 8.96435 14.7048 8.04906 14.7239Z"
          fill="#383838"
        />
      </svg>
    </div>
  );
}

export default BlackDot
