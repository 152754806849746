import React from "react";

const Q43 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="516"
        height="277"
        className="w-[516px] h-[277px] lg:h-[127px] lg:w-[234px]"
        viewBox="0 0 516 277"
        fill="none"
      >
        <path
          d="M281.815 176.809C377.077 159.128 416.769 106.085 418.754 0.000109857L516 0.000114108C514.015 157.163 434.631 247.532 281.815 271.106C256.015 275.035 184.569 277 184.569 277L178.615 277L0 277L4.12189e-06 182.702L184.569 182.702C184.569 182.702 256.015 182.702 281.815 176.809Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="516"
        height="280"
        className="w-[516px] h-[280px] 2xl:w-[411px] 2xl:h-[223px] xl:w-[327px] xl:h-[177px] lg:h-[127px] lg:w-[234px]"
        viewBox="0 0 516 280"
        fill="none"
      >
        <path
          d="M282.048 177.705C377.252 159.892 417.036 105.659 418.421 0.361329L516 0.361328C514.219 158.704 435.245 250.147 281.652 273.305C255.328 278.055 184.272 279.639 184.272 279.639L177.74 279.639L0 279.639L-1.13293e-06 184.633L184.272 184.633C184.272 184.633 256.713 183.643 281.85 177.705L282.048 177.705Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q43;
