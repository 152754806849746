import React from "react";

const Q23 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[277px] h-[277px] lg:w-[127px] lg:h-[127px]"
        viewBox="0 0 277 277"
        fill="none"
      >
        <path
          d="M0.243256 273.297V9.15527e-05H94.4583V185.081L277 183.205V276.757L0.243256 273.297Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[279px] h-[279px] 2xl:w-[220px] 2xl:h-[220px] xl:w-[175px] xl:h-[175px] lg:w-[126px] lg:h-[127px]"
        viewBox="0 0 279 279"
        fill="none"
      >
        <path
          d="M101.197 278.652H94.6774C94.6774 278.652 61.0923 278.652 17.4318 278.652C7.94891 278.652 0.0465393 270.747 0.0465393 261.261C0.0465393 219.562 0.0465393 183.989 0.0465393 183.989V177.665V177.468V0H94.875V183.989H279V278.85H101.592L101.197 278.652Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q23;
