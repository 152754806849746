import React from "react";

const M32 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M51.0638 66.7155C45.9574 39.577 30.6383 28.2693 2.02978e-06 27.7039L-2.81412e-06 5.9764e-05C45.3901 0.565436 71.4893 23.1808 78.2979 66.7155C79.4326 74.0655 80 94.4193 80 94.4193L80 96.1154L80 147L52.766 147L52.766 94.4193C52.766 94.4193 52.7659 74.0655 51.0638 66.7155Z"
          fill="white"
        />
        <path
          d="M51.0638 66.7155C45.9574 39.577 30.6383 28.2693 2.02978e-06 27.7039L-2.81412e-06 5.9764e-05C45.3901 0.565436 71.4893 23.1808 78.2979 66.7155C79.4326 74.0655 80 94.4193 80 94.4193L80 96.1154L80 147L52.766 147L52.766 94.4193C52.766 94.4193 52.7659 74.0655 51.0638 66.7155Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="146"
        viewBox="0 0 79 146"
        fill="none"
      >
        <path
          d="M50.1323 66.1932C45.0967 39.2567 29.7661 28.0005 -5.34058e-05 27.6085V0C44.761 0.504009 70.6105 22.8484 77.1568 66.3052C78.4996 73.7534 78.9473 93.8577 78.9473 93.8577V95.7058V145.995H52.0906V93.8577C52.0906 93.8577 51.8109 73.3614 50.1323 66.2492V66.1932Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M32;
