import React from "react";

const M31 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path d="M80 0V80H52.766V27.0423H0V0H80Z" fill="white" />
        <path
          d="M80 0V80H52.766V27.0423H0V0H80Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="79"
        viewBox="0 0 79 79"
        fill="none"
      >
        <path
          d="M50.2586 0.191574H52.1014C52.1014 0.191574 61.5947 0.191574 73.936 0.191574C76.6165 0.191574 78.8502 2.42728 78.8502 5.11012C78.8502 16.9034 78.8502 26.9641 78.8502 26.9641V28.7527V28.8085V79H52.0456V26.9641H0V0.135674H50.1469L50.2586 0.191574Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M31;
