import React from "react";

const M43 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147"
        height="81"
        viewBox="0 0 147 81"
        fill="none"
      >
        <path
          d="M80.2846 51.5639C107.423 46.4575 118.731 31.1383 119.296 0.500055L147 0.500055C146.435 45.8901 123.819 71.9894 80.2846 78.7979C72.9346 79.9327 52.5808 80.5 52.5808 80.5L50.8846 80.5L0 80.5L3.24763e-07 53.266L52.5808 53.266C52.5808 53.266 72.9346 53.266 80.2846 51.5639Z"
          fill="white"
        />
        <path
          d="M80.2846 51.5639C107.423 46.4575 118.731 31.1383 119.296 0.500055L147 0.500055C146.435 45.8901 123.819 71.9894 80.2846 78.7979C72.9346 79.9327 52.5808 80.5 52.5808 80.5L50.8846 80.5L0 80.5L3.24763e-07 53.266L52.5808 53.266C52.5808 53.266 72.9346 53.266 80.2846 51.5639Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="79"
        viewBox="0 0 146 79"
        fill="none"
      >
        <path
          d="M79.8009 50.1323C106.737 45.0967 117.994 29.7661 118.386 -5.2199e-05L145.994 -5.34058e-05C145.49 44.761 123.146 70.6105 79.6889 77.1568C72.2408 78.4996 52.1364 78.9473 52.1364 78.9473L50.2884 78.9473L-0.000549316 78.9473L-0.00055049 52.0906L52.1364 52.0906C52.1364 52.0906 72.6328 51.8109 79.7449 50.1323L79.8009 50.1323Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M43;
