import React from "react";

const Q41 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[277px] h-[277px] lg:w-[127px] lg:h-[124px]"
        viewBox="0 0 277 277"
        fill="none"
      >
        <path
          d="M277 273.297V9.15527e-05H182.785V185.081L0.243164 183.205V276.757L277 273.297Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[278px] h-[279px] 2xl:w-[220px] 2xl:h-[220px] xl:w-[176px] xl:h-[176px] lg:w-[127px] lg:h-[124px]"
        viewBox="0 0 279 279"
        fill="none"
      >
        <path
          d="M177.85 278.652H184.37C184.37 278.652 217.955 278.652 261.615 278.652C271.098 278.652 279 270.747 279 261.261C279 219.562 279 183.989 279 183.989V177.665V177.468V0H184.172V183.989H0.046875V278.85H177.455L177.85 278.652Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q41;
