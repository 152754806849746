import React from "react";

const Footer = () => {
  return (
    <div className="flex fixed md:relative bottom-0 w-full gap-5 justify-between items-center px-5 py-3 xs:px-[13.5px] xs:py-[15px] bg-[#fdfaf7]">
      <a
        href="https://noorahealth.org/"
        target="_blank"
        rel="noreferrer"
        className="cursor-pointer"
      >
        <img
          src="https://sapient-dev-bucket.s3.ap-south-1.amazonaws.com/dental-web-app/uploads/74096710859293613075.logo-light-full-1-%281%29.png"
          alt="logo"
          className="md:w-28"
        />
      </a>
      <h1 className="text-[16px] md:text-[12px] text-center font-playfair xs:leading-3">
        A <span className="italic font-playfair">decade</span> of caring
        together
      </h1>
      <a
        href=" http://noorahealth.org/ten-year-anniversary"
        target="_blank"
        rel="noreferrer"
      >
        <button className="bg-[#383838] p-3.5 px-5 md:p-2 md:px-3 text-[14px] rounded-full outline-none text-white md:text-[12px] xs:leading-3">
          Celebrate with us
        </button>
      </a>
    </div>
  );
};

export default Footer;
