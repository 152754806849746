import React from "react";

const M42 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M51.0638 80.2846C45.9574 107.423 30.6383 118.731 2.02978e-06 119.296L-2.81412e-06 147C45.3901 146.435 71.4893 123.819 78.2979 80.2847C79.4326 72.9347 80 52.5808 80 52.5808L80 50.8847L80 4.57764e-05L52.766 4.10146e-05L52.766 52.5808C52.766 52.5808 52.7659 72.9346 51.0638 80.2846Z"
          fill="white"
        />
        <path
          d="M51.0638 80.2846C45.9574 107.423 30.6383 118.731 2.02978e-06 119.296L-2.81412e-06 147C45.3901 146.435 71.4893 123.819 78.2979 80.2847C79.4326 72.9347 80 52.5808 80 52.5808L80 50.8847L80 4.57764e-05L52.766 4.10146e-05L52.766 52.5808C52.766 52.5808 52.7659 72.9346 51.0638 80.2846Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="79"
        height="146"
        viewBox="0 0 79 146"
        fill="none"
      >
        <path
          d="M50.1323 79.8068C45.0967 106.743 29.7661 117.999 -5.34058e-05 118.391V146C44.761 145.496 70.6105 123.152 77.1568 79.6948C78.4996 72.2466 78.9473 52.1423 78.9473 52.1423V50.2942V0.00531006H52.0906V52.1423C52.0906 52.1423 51.8109 72.6386 50.1323 79.7508V79.8068Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M42;
