import React from "react";
import Q11 from "../../assets/svg/LogoSvg/Q11";
import Q12 from "../../assets/svg/LogoSvg/Q12";
import Q13 from "../../assets/svg/LogoSvg/Q13";
import Q14 from "../../assets/svg/LogoSvg/Q14";

const Que1Logo = ({ id }) => {
  return (
    <div className="">
      <div
        className={`absolute bottom-[73px] md:bottom-0 right-0 ${
          id[1] === "Q1o1" ? "z-10" : ""
        }`}
      >
        <Q11 color="#F5F5F5" SelectedColor="#ED2777" flag={id[1] === "Q1o1"} />
      </div>
      <div
        className={`absolute bottom-[73px] md:bottom-0 right-0 ${
          id[1] === "Q1o2" ? "z-10" : ""
        }`}
      >
        <Q12 color="#F5F5F5" SelectedColor="#ED2777" flag={id[1] === "Q1o2"} />
      </div>
      <div
        className={`absolute bottom-[73px] md:bottom-0 right-0 ${
          id[1] === "Q1o3" ? "z-10" : ""
        }`}
      >
        <Q13 color="#F5F5F5" SelectedColor="#ED2777" flag={id[1] === "Q1o3"} />
      </div>
      <div className="absolute right-0 bottom-[73px] md:bottom-0 lg:right-0">
        {/* <div className="absolute right-[277px] bottom-0 lg:right-[117px]"> */}
        <Q14 color="#F5F5F5" SelectedColor="#ED2777" flag={id[1] === "Q1o4"} />
      </div>
    </div>
  );
};

export default Que1Logo;
