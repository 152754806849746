import React from "react";

const Q33 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[239px] h-[278px] lg:w-[125px] lg:h-[126px]"
        viewBox="0 0 239 278"
        fill="none"
      >
        <path
          d="M0.399994 0.8C0.299988 0.5 0.100006 0.3 0 0V95.5C2.3 95.8 4.3 96.2 6.2 96.6H8.2C102.1 114.2 141 174.3 143 278H239C237 121.5 160.8 26.2 8.2 2.7C6.2 0.8 2.29999 0.8 0.399994 0.8Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="516"
        height="280"
        className="w-[516px] h-[280px] 2xl:w-[411px] 2xl:h-[223px] xl:w-[327px] xl:h-[177px] lg:w-[233px] lg:h-[127px]"
        viewBox="0 0 516 280"
        fill="none"
      >
        <path
          d="M282.048 102.295C377.252 120.108 417.036 174.341 418.421 279.639L516 279.639C514.219 121.296 435.245 29.8527 281.652 6.69507C255.328 1.94479 184.272 0.361324 184.272 0.361324L177.74 0.361324L-4.14494e-06 0.361322L-5.27787e-06 95.3671L184.272 95.3671C184.272 95.3671 256.713 96.3568 281.85 102.295L282.048 102.295Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q33;
