import React from "react";

const Q13 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[277px] h-[277px] lg:w-[127px] lg:h-[127px]"
        viewBox="0 0 277 277"
        fill="none"
      >
        <path
          d="M0.243256 3.45946V276.757H94.4583V91.6757L277 93.5517V0L0.243256 3.45946Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[279px] h-[279px] 2xl:w-[220px] 2xl:h-[220px] xl:w-[176px] xl:h-[176px] lg:w-[127px] lg:h-[127px]"
        viewBox="0 0 279 279"
        fill="none"
      >
        <path
          d="M101.15 0.348022H94.6305C94.6305 0.348022 61.0454 0.348022 17.3849 0.348022C7.90204 0.348022 -0.000335693 8.25305 -0.000335693 17.7391C-0.000335693 59.4381 -0.000335693 95.0107 -0.000335693 95.0107V101.335V101.532V279H94.8281V95.0107H278.953V0.15036H101.545L101.15 0.348022Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q13;
