import React from "react";

const Q22 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[512px] h-[509px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 512 509"
        fill="none"
      >
        <path
          d="M228.863 268.421C230.819 268.421 232.774 268.421 236.686 270.377C236.686 272.333 236.686 276.245 238.642 278.201C262.111 430.763 355.537 507.044 512 509L512 413.16C408.343 411.204 350.122 372.085 332.52 278.201L332.52 276.245C326.652 248.862 326.652 182.36 326.652 182.36C326.652 182.36 260.156 182.36 232.775 176.493L230.819 176.493C136.941 158.889 97.8252 106.079 95.8694 0.459468L0.035765 0.459473C-1.92002 154.978 76.3116 244.95 228.863 268.421Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[516px] h-[516px] 2xl:w-[410px] 2xl:h-[410px] xl:w-[327px] xl:h-[327px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 516 516"
        fill="none"
      >
        <path
          d="M243.764 282.407C243.368 280.233 242.973 277.664 242.578 274.699C239.812 274.304 237.244 273.909 234.873 273.513C81.5676 250.194 2.93909 158.891 0.963501 0.790222H98.3601C99.743 105.927 139.65 160.076 234.478 177.863C235.269 177.863 236.256 178.258 237.244 178.456C264.31 183.989 331.677 184.78 332.073 184.78C332.073 184.977 333.06 252.368 338.394 279.64C338.394 280.628 338.79 281.616 338.987 282.407C356.767 377.464 410.899 417.187 516 418.571V516C357.953 514.221 266.681 435.369 243.566 282.011L243.764 282.407Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q22;
