import React from "react";

const M33 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="78"
        viewBox="0 0 69 78"
        fill="none"
      >
        <path
          d="M68 78H40C42.8 38.4 14.5 27.5 -2.28882e-05 27V0C58.8 4.8 69.8333 54 68 78Z"
          fill="white"
        />
        <path
          d="M68 78H40C42.8 38.4 14.5 27.5 -2.28882e-05 27V0C58.8 4.8 69.8333 54 68 78Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="79"
        viewBox="0 0 146 79"
        fill="none"
      >
        <path
          d="M79.8009 28.8149C106.737 33.8506 117.994 49.1812 118.386 78.9473L145.994 78.9473C145.49 34.1863 123.146 8.33675 79.6889 1.79045C72.2408 0.447616 52.1364 -4.10265e-06 52.1364 -4.10265e-06L50.2884 -4.18343e-06L-0.000549316 -6.38163e-06L-0.00055049 26.8566L52.1364 26.8566C52.1364 26.8566 72.6328 27.1364 79.7449 28.8149L79.8009 28.8149Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M33;
