import React from "react";

const M12 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147"
        height="147"
        viewBox="0 0 147 147"
        fill="none"
      >
        <path
          d="M69.5423 66.1557C69.5423 66.7211 69.5423 67.2864 68.977 68.4171C68.4116 68.4171 67.2808 68.4171 66.7154 68.9824C22.6154 75.7666 0.565385 101.772 0 147H27.7039C28.2692 117.037 39.5769 101.207 66.7154 96.119H67.2808C75.1962 94.4229 94.4193 94.423 94.4193 94.423C94.4193 94.423 94.4192 75.2012 96.1154 67.2864V66.7211C101.204 39.5845 116.469 28.2776 147 27.7122V0.0103447C102.335 -0.555 76.3269 22.0588 69.5423 66.1557Z"
          fill="white"
        />
        <path
          d="M69.5423 66.1557C69.5423 66.7211 69.5423 67.2864 68.977 68.4171C68.4116 68.4171 67.2808 68.4171 66.7154 68.9824C22.6154 75.7666 0.565385 101.772 0 147H27.7039C28.2692 117.037 39.5769 101.207 66.7154 96.119H67.2808C75.1962 94.4229 94.4193 94.423 94.4193 94.423C94.4193 94.423 94.4192 75.2012 96.1154 67.2864V66.7211C101.204 39.5845 116.469 28.2776 147 27.7122V0.0103447C102.335 -0.555 76.3269 22.0588 69.5423 66.1557Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="146"
        viewBox="0 0 146 146"
        fill="none"
      >
        <path
          d="M68.6305 66.0649C68.5189 66.6798 68.4072 67.4064 68.2955 68.2447C67.5137 68.3565 66.7877 68.4683 66.1176 68.5801C22.7835 75.1754 0.55806 100.998 -0.000366211 145.712H27.5302C27.9211 115.977 39.2013 100.662 66.0059 95.6321C66.2293 95.6321 66.5085 95.5203 66.7877 95.4644C74.4382 93.8994 93.4806 93.6758 93.5923 93.6758C93.5923 93.6199 93.8715 74.5606 95.3793 66.8474C95.3793 66.568 95.491 66.2885 95.5468 66.0649C100.573 39.1806 115.874 27.9463 145.582 27.555V0C100.908 0.503033 75.1083 22.8041 68.5747 66.1767L68.6305 66.0649Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M12;
