import React from "react";

const Q21 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[277px] h-[516px] lg:w-[127px] lg:h-[234px]"
        viewBox="0 0 277 516"
        fill="none"
      >
        <path
          d="M100.192 281.815C117.872 377.077 170.915 416.769 277 418.754L277 516C119.837 514.015 29.4681 434.631 5.89365 281.815C1.96457 256.015 3.22711e-05 184.569 3.22711e-05 184.569L3.12301e-05 178.615L0 0L94.2978 -1.64876e-05L94.2978 184.569C94.2978 184.569 94.2979 256.015 100.192 281.815Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[280px] h-[516px] 2xl:w-[223px] 2xl:h-[411px] xl:w-[177px] xl:h-[327px] lg:w-[127px] lg:h-[234px]"
        viewBox="0 0 280 516"
        fill="none"
      >
        <path
          d="M101.933 282.048C119.747 377.252 173.979 417.036 279.277 418.421V516C120.934 514.219 29.4914 435.245 6.33375 281.652C1.58346 255.328 0 184.272 0 184.272V177.74V0H95.0058V184.272C95.0058 184.272 95.9954 256.713 101.933 281.85V282.048Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q21;
