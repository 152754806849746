import React from "react";
import { createContext, useState } from "react";

const Context = createContext("");

export function RoleStore(props) {
  const [submitData, setSubmitData] = useState({});
  const [dotCount, setDotCount] = useState([{ grayDot: null, blackDot: null }]);

  return (
    <Context.Provider
      value={{
        submitData,
        dotCount,
        ...{
          setSubmitData,
          setDotCount,
        },
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export default Context;
