import CreamDot from "../assets/svg/CreamDot";
import BlackDot from "../assets/svg/BlackDot";

const questions = [
  {
    question: "What can we call you?",
    options: [],
    type: "input",
    placeholder: "Enter your name",
  },
  {
    question: "Let’s begin, which one of these describes you best?",
    type: "radio",
    options: [
      {
        value: "A",
        label: "I have cared for someone in my family",
        id: "Q1o1",
      },
      {
        value: "B",
        label: "I have cared for someone who feels like family",
        id: "Q1o2",
      },
      {
        value: "C",
        label:
          "I have not been a primary caregiver but I have supported the act of caregiving",
        id: "Q1o3",
      },
      {
        value: "D",
        label:
          "I have no experience in giving or receiving care but it is likely that I will at some point",
        id: "Q1o4",
      },
    ],
  },
  {
    question:
      "Great!  Let’s talk about attributes, which caregiver trait do you resonate with the most?",
    type: "radio",
    options: [
      {
        value: "A",
        label:
          "I'm a problem solver. I keep myself well informed and make decisions best suited for the one I'm caring for.",
        id: "Q2o1",
      },
      {
        value: "B",
        label:
          "I'm the practical helper who is attentive to the one I'm caring for and do the needful.",
        id: "Q2o2",
      },
      {
        value: "C",
        label:
          "I'm an emotional supporter. I try to keep spirits up and encourage the one I care for. After all, it's mind over matter.",
        id: "Q2o3",
      },
    ],
  },
  {
    question:
      "When the going gets tough or situations become  tense regarding the health of someone you care for, how would you react?",
    type: "radio",
    options: [
      {
        value: "A",
        label: "I'm calm and unwavering",
        id: "Q3o1",
      },
      {
        value: "B",
        label:
          "I'm nervous but I will stay strong for the person I'm caring for",
        id: "Q3o2",
      },
      {
        value: "C",
        label:
          "I will feel for the person I care for and their distress is mine",
        id: "Q3o3",
      },
    ],
  },
  {
    question:
      "What do you think motivates someone to become a caregiver ? (Can choose multiple answers)",
    type: "checkbox",
    options: [
      {
        value: "A",
        label: "Family obligations and a sense of responsibility",
        id: "Q4o1",
      },
      {
        value: "B",
        label:
          "Feeling of goodness or fulfillment from the act of caring and being of help",
        id: "Q4o2",
      },
      {
        value: "C",
        label: "A sense of being acknowledged",
        id: "Q4o3",
      },
      {
        value: "D",
        label: "Situational (nobody else was there)",
        id: "Q4o4",
      },
      // {
      //   value: "E",
      //   label: "other",
      //   id: "Q4o5",
      // },
    ],
  },
  {
    question:
      "What are the more fulfilling parts of caregiving? (Can choose multiple answers)",
    type: "checkbox",
    options: [
      {
        value: "A",
        label: "Compassion - the act of giving or receiving care",
        image: CreamDot,
        id: "Lg1",
      },
      {
        value: "B",
        label: "Relief when the patient feels good",
        image: CreamDot,
        id: "Lg2",
      },
      {
        value: "C",
        label:
          "Giving back - karma (taking care of someone who has cared for you)",

        image: CreamDot,
        id: "Lg3",
      },
      {
        value: "D",
        label: "Acknowledgement and being appreciated",
        image: CreamDot,
        id: "Lg4",
      },
      {
        value: "E",
        label: "other",
        id: "Lg5",
      },
    ],
  },
  {
    question:
      "What are the more difficult parts of caregiving? (Can choose multiple answers)",
    type: "checkbox",
    options: [
      {
        value: "A",
        label: "Life altering - no sense of normalcy, delay in life plans",
        image: BlackDot,
        id: "Dg1",
      },
      {
        value: "B",
        label: "Emotional wellbeing severely affected",
        image: BlackDot,
        id: "Dg2",
      },
      {
        value: "C",
        label: "Halt or delay in professional/personal growth",
        image: BlackDot,
        id: "Dg3",
      },
      {
        value: "D",
        label: "Not having control over a loved one’s wellbeing",
        image: BlackDot,
        id: "Dg4",
      },
      {
        value: "E",
        label: "other",
        id: "Dg5",
      },
    ],
  },
  {
    question:
      "If you would like, please share a meaningful caregiving moment with us that has been significant in your journey.",
    options: [],
    type: "input",
  },
  // {
  //   question:
  //     "Please leave your email address if you would like to receive your personalised tile/ unique caregiving style via email / if you would like to share  more about your caregiving experience",
  //   options: [],
  //   type: "input",
  // },
];

export default questions;
