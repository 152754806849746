import React from "react";
import Q31 from "../../assets/svg/LogoSvg/Q31";
import Q32 from "../../assets/svg/LogoSvg/Q32";
import Q33 from "../../assets/svg/LogoSvg/Q33";

const Que3Logo = ({ id }) => {
  return (
    <div>
      <div>
        <div
          className={`absolute bottom-[73px] md:bottom-0 left-0 ${
            id[3] === "Q3o1" ? "z-10" : ""
          }`}
        >
          <Q31
            color="#F5F5F5"
            SelectedColor="#00986B"
            flag={id[3] === "Q3o1"}
          />
        </div>
        <div
          className={`absolute bottom-[73px] md:bottom-0 left-0 ${
            id[3] === "Q3o2" ? "z-10" : ""
          }`}
        >
          <Q32
            color="#F5F5F5"
            SelectedColor="#00986B"
            flag={id[3] === "Q3o2"}
          />
        </div>
        <div className="absolute bottom-[73px] md:bottom-0 left-0 lg:left-0">
          {/* <div className="absolute bottom-0 left-[277px] lg:left-[117px]"> */}
          <Q33
            color="#F5F5F5"
            SelectedColor="#00986B"
            flag={id[3] === "Q3o3"}
          />
        </div>
      </div>
    </div>
  );
};

export default Que3Logo;
