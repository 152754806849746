import React from "react";

const Button = ({ title, stop = false }) => {
  return (
    <div>
      <button
        className="mt-5 sm:mt-2 sm:text-[14px] bg-[#383838] p-3.5 px-8 rounded-full outline-none text-white font-semibold"
        disabled={stop}
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
