import React from "react";

const Q44 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[512px] h-[509px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 470 471"
        fill="none"
      >
        <path
          d="M249.2 257C249.2 255.2 249.2 253.4 251 249.8C252.8 249.8 256.4 249.8 258.2 248C397.3 228.1 469.5 145 469.5 0.500092H381C379.2 96.2001 343.1 146.8 256.4 163H254.6C229.3 166.6 167.9 168.4 167.9 168.4C167.9 168.4 167.9 229.8 162.5 255.1V256.9C146.201 343.6 97.5005 379.7 0.000488281 381.5V470.1C144.4 470.1 227.5 397.9 249.2 257Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-[516px] h-[516px] 2xl:w-[410px] 2xl:h-[410px] xl:w-[327px] xl:h-[327px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 516 516"
        fill="none"
      >
        <path
          d="M273.199 282.407C273.594 280.233 273.99 277.664 274.385 274.699C277.15 274.304 279.719 273.909 282.089 273.513C435.395 250.194 514.024 158.891 515.999 0.790222H418.603C417.22 105.927 377.313 160.076 282.485 177.863C281.694 177.863 280.707 178.258 279.719 178.456C252.653 183.989 185.285 184.78 184.89 184.78C184.89 184.977 183.903 252.368 178.568 279.64C178.568 280.628 178.173 281.616 177.976 282.407C160.195 377.464 106.064 417.187 0.962891 418.571V516C159.01 514.221 250.282 435.369 273.397 282.011L273.199 282.407Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q44;
