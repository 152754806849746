import React from "react";
import QuestionForm from "./components/QuestionForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import EmailForm from "./components/EmailForm";
import IntroPage from "./components/IntroPage";
import SubmitPage from "./components/SubmitPage";
import { Toaster } from "react-hot-toast";
import { RoleStore } from "./contexts/roleContext";
import Dashboard from "./dashboardComponent/Dashboard";

const App = () => {
  return (
    <div className="bg-[#fdfaf7]">
      <RoleStore>
        <Router>
          <Routes>
            {/* <Route path="/" element={<EmailForm />} />
          <Route path="/intro" element={<IntroPage />} /> */}
            <Route path="/" element={<IntroPage />} />
            <Route path="/form" element={<QuestionForm />} />
            <Route path="/submit/:id" element={<SubmitPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </Routes>
        </Router>
        <Toaster position="top-right" reverseOrder={false} />
      </RoleStore>
    </div>
  );
};

export default App;
