import React from "react";

const M22 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147"
        height="147"
        viewBox="0 0 147 147"
        fill="none"
      >
        <path
          d="M69.5423 80.8443C69.5423 80.279 69.5423 79.7137 68.977 78.583C68.4116 78.583 67.2808 78.583 66.7154 78.0176C22.6154 71.2335 0.565385 45.2276 0 6.10352e-05H27.7039C28.2692 29.9633 39.5769 45.793 66.7154 50.8811H67.2808C75.1962 52.5771 94.4193 52.5771 94.4193 52.5771C94.4193 52.5771 94.4192 71.7988 96.1154 79.7137V80.279C101.204 107.416 116.469 118.722 147 119.288V146.99C102.335 147.555 76.3269 124.941 69.5423 80.8443Z"
          fill="white"
        />
        <path
          d="M69.5423 80.8443C69.5423 80.279 69.5423 79.7137 68.977 78.583C68.4116 78.583 67.2808 78.583 66.7154 78.0176C22.6154 71.2335 0.565385 45.2276 0 6.10352e-05H27.7039C28.2692 29.9633 39.5769 45.793 66.7154 50.8811H67.2808C75.1962 52.5771 94.4193 52.5771 94.4193 52.5771C94.4193 52.5771 94.4192 71.7988 96.1154 79.7137V80.279C101.204 107.416 116.469 118.722 147 119.288V146.99C102.335 147.555 76.3269 124.941 69.5423 80.8443Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="146"
        height="146"
        viewBox="0 0 146 146"
        fill="none"
      >
        <path
          d="M68.6305 79.9351C68.5189 79.3202 68.4072 78.5936 68.2955 77.7553C67.5137 77.6435 66.7877 77.5317 66.1176 77.4199C22.7835 70.8246 0.55806 45.0022 -0.000366211 0.288239H27.5302C27.9211 30.0231 39.2013 45.3376 66.0059 50.3679C66.2293 50.3679 66.5085 50.4797 66.7877 50.5356C74.4382 52.1006 93.4806 52.3242 93.5923 52.3242C93.5923 52.3801 93.8715 71.4394 95.3793 79.1526C95.3793 79.432 95.491 79.7115 95.5468 79.9351C100.573 106.819 115.874 118.054 145.582 118.445V146C100.908 145.497 75.1083 123.196 68.5747 79.8233L68.6305 79.9351Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default M22;
